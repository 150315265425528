<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="is_load">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">

              <b-overlay :show="sincronizar">
                <button @click="sincronizarLoad" class="btn btn-success">Sincronizar planos</button>
              </b-overlay>


              <b-table :fields="colunas" :items="dados" class="table table-striped">
                <template v-slot:cell(name)="data">

                  <span v-if="data.item.id.toString().length <= 5" class="btn btn-sm btn-outline-danger">SANDBOX</span>
                  <span v-if="data.item.id.toString().length > 5" class="btn btn-sm btn-outline-info">PRODUCAO</span>

                  <a class="btn btn-sm btn-success" :href="url_vindi+'admin/plans/'+data.item.id" target="_blank"><i class="dripicons-link"></i> vindi</a> {{ data.item.name }}


                  <a style="margin: 0  10px 0 0 0" class="btn btn-sm btn-dark" :href="url_site+'plano?id='+data.item.id+'&boleto=true'" target="_blank">Boleto</a>
                  <a style="margin: 0  10px 0 0 0" class="btn btn-sm btn-dark" :href="url_site+'plano?id='+data.item.id+'&credito=true'" target="_blank">Crédito</a>
                  <a style="margin: 0  10px 0 0 0" class="btn btn-sm btn-dark" :href="url_site+'plano?id='+data.item.id+'&boleto=true&credito=true'" target="_blank">Todas formas de pagamento</a>
                </template>
                <template v-slot:cell(produtos)="data">

                                   <span v-if="data.item.plan_items">
                                        <span v-for="(item,index) in data.item.plan_items" :key="item.id">
                                           <span v-if="index>0">, </span> <a class="btn btn-sm btn-success" :href="url_vindi+'admin/products/'+item.product.id" target="_blank"><i class="dripicons-link"></i> vindi</a>  {{ item.product.name }}
                                        </span>
                                   </span>
                </template>
                <template v-slot:cell(contador)="data">
                  {{ data.index + 1 }}
                </template>
              </b-table>

            </div>
          </div>
        </div>
      </div>
    </b-overlay>

  </Layout>
</template>

<script>

import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Vue from "vue";
import ambienteConfig from "@/ambiente.config";

export default {
  name: "planos",
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      url_vindi:ambienteConfig.VUE_APP_DEFAULT_VINDI || 'https://sandbox-app.vindi.com.br/',
      url_site:ambienteConfig.URL_SITE ,
      is_load: false,
      sincronizar: false,
      dados: [],
      colunas: [
        {key: 'contador', label: ''},
        {key: 'id', label: 'ID'},
        {key: 'name', label: 'Plano'},
        {key: 'code', label: 'Código'},
        {key: 'status', label: 'Status'},
        {key: 'interval_name', label: 'Tipo'},
        {key: 'produtos', label: 'Produto(s)'},
      ],
      title: "Planos",
      items: [
        {
          text: "Home"
        },
        {
          text: "Planos",
          active: true
        }
      ]
    };
  },
  mounted() {
    this.getPlanos()


  },
  methods: {
    sincronizarLoad() {
      this.sincronizar = true;
      axios.get('planos').then(response => {
        this.sincronizar = false;
        console.log(response)
        Vue.swal({
          title: 'Sucesso!',
          text: 'Planos sincronizados com sucesso!',
          type: 'success',
          confirmButtonText: 'Ok'
        })
        this.getPlanos()
      }).catch(error => {
        this.sincronizar = false;
        console.log(error)
        Vue.swal({
          title: 'Erro!',
          text: 'Erro ao sincronizar planos!',
          type: 'error',
          confirmButtonText: 'Ok'
        })
      })

    },
    getPlanos() {
      this.is_load = true;
      axios.get('planos').then(response => {
        this.dados = response.data;
        this.is_load = false;
        console.log(this.dados)
      }).catch(error => {
        this.is_load = false;
        this.dados = [];
        console.log(error)
      })
    }
  }
}
</script>

<style>

</style>